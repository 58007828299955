<template>
    <router-view/>
</template>

<script>
export default {
    name: 'SimulatorTop'
}

</script>
